exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-manual-js": () => import("./../../../src/pages/manual.js" /* webpackChunkName: "component---src-pages-manual-js" */),
  "component---src-pages-member-js": () => import("./../../../src/pages/member.js" /* webpackChunkName: "component---src-pages-member-js" */),
  "component---src-templates-pages-jsx-content-file-path-data-pages-index-mdx": () => import("./../../../src/templates/pages.jsx?__contentFilePath=/home/runner/work/chatbus_homepage/chatbus_homepage/data/pages/index.mdx" /* webpackChunkName: "component---src-templates-pages-jsx-content-file-path-data-pages-index-mdx" */)
}

